/**
 * Mapping of CMS content models.
 * It consists the logic of transpiling CMS data into React Components
 * @module "contentManager.map"
 */
import { List } from 'immutable';
import T from 'ramda/es/T';
import identity from 'ramda/es/identity';
import React from 'react';

import { basedOnType, camelCaseify, isAnyOfType } from './utils';
import { getField, getFieldIn, getComponentName, getId } from '../../utils/cmsData';
import { ContentManager } from './';

import { HeroHome } from '../heroHome';
import { BigText } from '../bigText';
import { Text } from '../text';
import { ArticleTextBlock } from '../../layouts/article/articleTextBlock';
import { TextSection } from '../textSection';
import { PageLinkSection } from '../pageLinkSection';
import { VideoTextSection } from '../videoTextSection';
import { ParallaxTextsSection } from '../parallaxTextsSection';
import { ImageWithBarSection } from '../imageWithBarSection';
import { SubscribeSection } from '../subscribeSection';
import { IframeForm } from '../iframeForm';
import { Iframe } from '../iframe';
import { ExternalEmbed } from '../externalEmbed';
import { CardListThreeColumns } from '../cardListThreeColumns';
import { CardListTwoColumns } from '../cardListTwoColumns';
import { ImageWithTextList } from '../imageWithTextList';
import { CarouselSection } from '../carouselSection';
import { BadgesSection } from '../badgesSection';
import { Hero } from '../hero';
import { HeroSimple } from '../heroSimple';
import { CategoryCarousel } from '../categoryCarousel';
import { FeaturedInSection } from '../featuredInSection';
import { AllNewsSection } from '../allNewsSection';
import { FeaturedArticleHeroSection } from '../featuredArticleHeroSection';
import { ImageSection } from '../imageSection';
import { ThreeColumnStatisticsSection } from '../threeColumnStatisticsSection';
import { OtherWaysToDonate } from '../otherWaysToDonate';
import { SectionDecoration } from '../sectionDecoration';
import { LinksSection } from '../linksSection';
import { CtaCardsSection } from '../ctaCardsSection';
import { ArticleListBackgroundSection } from '../articleListBackgroundSection';
import { EventsSection } from '../eventsSection';
import { LegalActionSection } from '../legalActionSection';
import { ArticleFigureBlock } from '../../layouts/article/articleFigureBlock';
import { ArticleStatsBlock } from '../../layouts/article/articleStatsBlock';
import { ArticleQuoteBlock } from '../../layouts/article/articleQuoteBlock';
import { ArticleCtaBlock } from '../../layouts/article/articleCtaBlock';
import { FlagCta } from '../flagCta';
import { JobListSection } from '../jobListSection';
import { PreForm } from '../preForm';
import { WideImageSection } from '../wideImageSection';
import { WideImageDonateSection } from '../wideImageDonateSection';
import { ListWithImageSection } from '../listWithImageSection';
import { SectionTriangleDecoration } from '../sectionTriangleDecoration';
import { SignInList } from '../signInList';
import { ArticleCardsList } from '../articleCardsList';
import { VerticalStatisticsList } from '../verticalStatisticsList';
import { ImageWithBackgroundSection } from '../imageWithBackgroundSection';
import { WideCardsList } from '../wideCardsList';
import { ProgressBar } from '../progressBar';
import { VideoFullWidthSection } from '../videoFullWidthSection';
// HomePage Components
import { ButtonArray } from '../homePage/buttonArray';
import { ColorChangeableDivider } from '../homePage/colorChangeableDivider';
import { DynamicHeroCarousel } from '../homePage/dynamicHeroCarousel';
import { OneUpNewsMajor } from '../homePage/oneUpNewsMajor';
import { ThirdBlock } from '../homePage/thirdBlock';
import { ThreeUpImpactStats } from '../homePage/threeUpImpactStats';
import { ThreeUpScrollingBlock } from '../homePage/threeUpScrollingBlock';
import { NotchedHeaderSection } from '../homePage/notchedHeaderSection';
import { FramedContent } from '../homePage/framedContent';
import { AnchoredScrollText } from '../homePage/anchoredScrollText';
import MissionBreakout from '../homePage/missionBreakout/missionBreakout.component';
import { GivingBreakout } from '../homePage/givingBreakout';
import { FundraisingHero } from '../homePage/fundraisingHero';
import { LegacyGivingBreakout } from '../homePage/givingBreakout/legacyGivingBreakout';

const HERO_HOME = 'homepageHero';
const TEXT_SECTION = 'textItem';
const PARALLAX_TEXT_SECTION = 'parallaxTextSection';
const IMAGE_WITH_FLOATING_BAR_SECTION = 'imageWithFloatingBarSection';
const SUBSCRIBE_SECTION = 'subscribeSection';
const EXTERNAL_EMBED = 'externalEmbed';
const NEWS_LIST = 'genericNewsListSection';
const LIST_SECTION = 'genericListSection';
const MEDIA_ITEM = 'mediaItem';
const HERO_SECTION = 'genericHeroSection';
const NEWS_GRID_SECTION = 'allNewsGridSection';
const DECORATION_SECTION = 'articleDecorationSection';
const LINKS_SECTION = 'linksSection';
const ARTICLE_STATS_SECTION = 'articleStatisticSection';
const ARTICLE_QUOTE = 'articleQuoteSection';
const LINK = 'linkItem';
const FLAG_CTA = 'attachmentFloatingItem';
const JOB_LIST_SECTION = 'greenhouseListSection';
const TAKE_ACTION_PRE_FORM = 'takeActionPreForm';
const WIDE_IMAGE_SECTION = 'wideImageWithTextSection';
const LIST_WITH_IMAGE_SECTION = 'textListWithImageSection';
const STATISTIC_ITEM = 'statisticItem';
// HomePage Components
const BUTTON_ARRAY = 'buttonArray';
const COLOR_CHANGEABLE_DIVIDER = 'colorChangeableDivider';
const DYNAMIC_HERO_CAROUSEL = 'dynamicHeroCarousel';
const FRAMED_CONTENT = 'framedContentOnBackground';
const ONE_UP_NEWS_MAJOR = 'oneUpNewsMajor';
const THIRD_BLOCK = 'thirdBlock';
const THREE_UP_IMPACT_STATS = 'threeUpImpactStats';
const THREE_UP_SCROLLING_BLOCK = 'threeUpScrollingBlock';
const ANCHORED_SCROLL_TEXT = 'anchoredScrollText';
const MISSION_BREAKOUT = 'missionBreakout';
const FUNDRAISING_HERO = 'fundraisingHero';
const GIVING_BREAKOUT = 'givingBreakout';
const LEGACY_GIVING_BREAKOUT = 'legacyGivingBreakout';
const NOTCHED_HEADER_SECTION = 'notchedHeaderSection';

/**
 * @method
 * @summary Checks whether the component is a hero component
 **/
export const isHeroComponent = isAnyOfType([[HERO_HOME], [HERO_SECTION]]);

/**
 * @method
 * @summary Checks whether the component is a hero component and it should display absolute positioned header
 **/
export const isAbsoluteMenuHeroComponent = isAnyOfType([
  [HERO_HOME],
  [HERO_SECTION, 'Standard Hero'],
  [HERO_SECTION, 'Thank you Hero'],
]);

/**
 * @method
 * @summary Checks whether the component is a hero component and it contains the H1 title element
 **/
export const isHeroWithTitleComponent = isAnyOfType([
  [HERO_SECTION, 'Standard Hero'],
  [HERO_SECTION, 'Simple Hero'],
  [HERO_SECTION, 'Thank you Hero'],
]);

/**
 * @summary
 * This object contains the mapping between CMS data and ReactComponents.
 * Each key in the object is a __contentType__ id from CMS.
 * The value cna be either an object or a function.
 * If it's an object, it should be always applied for this __contentType__.
 * If it's a function, it can return config object dynamically based on the received data.
 * Commonly used function here is a `basedOnType()` function, which returns an object based on data `type` CMS field.
 *
 * Each returned config object should contain 2 properties:
 * * **component** - Indicates what component should be displayed
 * * **props** - Indicates what props should the component receive (derived from CMS data)
 *
 * **props** is a array of either:
 * * strings (prop with this name will be populated with corresponding field value from data object)
 * * objects with `name` property (it defines what prop name will be used on the component)
 *   and with `path` or `getter` property.
 * `getter` can define any function that will be invoked to get the value to inject into the prop,
 *  while `path` is a shorthand that defines a path of fields to access to get the value to inject.
 **/
const COMPONENTS_MAP = {
  [HERO_HOME]: {
    component: HeroHome,
    props: [
      {
        name: 'image',
        path: ['heroImageDesktop'],
      },
      {
        name: 'mobileImage',
        path: ['heroImageMobile'],
      },
      {
        name: 'titleImage',
        path: ['titleImage', 'fields', 'file', 'url'],
      },
      {
        name: 'imageAlt',
        path: ['titleImage', 'fields', 'title'],
      },
      'name',
      'description',
      'badges',
      'includeDonateBox',
      'donateTitle',
      'donateDescription',
      'donateUrl',
      'donateDefaultAmount',
      'donateIsRecurring',
      'hideGradient',
      'focalPointX',
      'focalPointY',
    ],
  },
  [HERO_SECTION]: basedOnType({
    'Simple Hero': {
      component: HeroSimple,
      props: ['background', 'title', 'description', 'overlap'],
    },
    'Standard Hero': {
      component: Hero,
      props: [
        'title',
        { name: 'mobileImage', path: ['heroImageMobile'] },
        { name: 'desktopImage', path: ['heroImageDesktop'] },
        { name: 'label', path: ['labelAboveTitle'] },
        'videoId',
        'videoHost',
        { name: 'backgroundColor', getter: getField('background') },
        'size',
        'titleSize',
        'direction',
        'verticalLine',
        'description',
        'socialsTitle',
        { name: 'showSocials', path: ['socials'] },
        'form',
        { name: 'thankYouFormState', path: ['thankYouText'] },
        'hideGradient',
        'wideHeroText',
        'shareUrl',
        'includeContentBoxLayout',
        'gradientOpacity',
        'focalPointX',
        'focalPointY',
        'anchorButtonText',
        'anchorButtonSectionId',
      ],
    },
    'Featured Article Hero': {
      component: FeaturedArticleHeroSection,
      props: [{ name: 'title', path: ['title'] }, 'article', 'background'],
    },
  }),
  [TAKE_ACTION_PRE_FORM]: {
    component: PreForm,
    props: [
      'title',
      'description',
      {
        name: 'checkboxList',
        getter: item => [
          {
            title: getField('checkboxCard1Title', item),
            description: getField('checkboxCard1Description', item),
            url: getField('checkboxCard1LearnMoreUrl', item),
            activistCode: getField('checkboxCard1ActivistCode', item),
            disableWithMessage: getField('checkboxCard1DisableWithMessage', item),
          },
          {
            title: getField('checkboxCard2Title', item),
            description: getField('checkboxCard2Description', item),
            url: getField('checkboxCard2LearnMoreUrl', item),
            activistCode: getField('checkboxCard2ActivistCode', item),
            disableWithMessage: getField('checkboxCard2DisableWithMessage', item),
          },
          {
            title: getField('checkboxCard3Title', item),
            description: getField('checkboxCard3Description', item),
            url: getField('checkboxCard3LearnMoreUrl', item),
            activistCode: getField('checkboxCard3ActivistCode', item),
            disableWithMessage: getField('checkboxCard3DisableWithMessage', item),
          },
        ],
      },
      'buttonLabel',
      'form',
      'postForm',
    ],
  },
  [PARALLAX_TEXT_SECTION]: {
    component: ParallaxTextsSection,
    props: [
      'block1',
      'block2',
      {
        name: 'squareTextImage',
        path: ['squareTextImage', 'fields', 'file', 'url'],
      },
      {
        name: 'squareTextImageAlt',
        path: ['squareTextImage', 'fields', 'title'],
      },
      'backgroundImage',
      'backgroundImageMobile',
    ],
  },
  [TEXT_SECTION]: basedOnType({
    'Paragraph Section': {
      component: ArticleTextBlock,
      props: ['title', 'text'],
    },
    'Page Text Section': {
      component: TextSection,
      props: [
        'title',
        'textHeader',
        'text',
        'isHeaderLeft',
        'isTextLeft',
        { name: 'linkList', getter: getFieldIn(['linkList']) },
        'background',
      ],
    },
    'Page Text Section (two columns)': {
      component: TextSection,
      props: [
        'textHeader',
        'text',
        'isHeaderLeft',
        'isTextLeft',
        'background',
        {
          name: 'isTwoColumns',
          getter: T,
        },
      ],
    },
    'Big Text': {
      component: BigText,
      props: ['title', 'text'],
    },
    Line: {
      component: Text,
      props: ['text'],
    },
    'Video Text': {
      component: VideoTextSection,
      props: [
        'title',
        'background',
        { name: 'textBlockTitle', path: ['textHeader'] },
        { name: 'textBlockText', path: ['text'] },
        { name: 'linkList', getter: getFieldIn(['linkList']) },
        'videoImage',
        'videoId',
        'videoHost',
      ],
    },
  }),
  [IMAGE_WITH_FLOATING_BAR_SECTION]: {
    component: ImageWithBarSection,
    props: [
      'text',
      'name',
      'image',
      'imageMobile',
      'barTitle',
      'barSubtitle',
      'barUrl',
      'barButtonLabel',
      { name: 'duration', path: ['barFloatDuration'] },
    ],
  },
  [MEDIA_ITEM]: basedOnType({
    Image: {
      component: ImageSection,
      props: [
        { name: 'title', path: ['title'] },
        { name: 'name', path: ['name'] },
        { name: 'description', path: ['text'] },
        { name: 'imageSrc', path: ['image', 'fields', 'file', 'url'] },
        { name: 'alt', path: ['imageAlt'] },
      ],
    },
    'Image (with title)': {
      component: ImageSection,
      props: [
        'title',
        { name: 'imageSrc', path: ['image', 'fields', 'file', 'url'] },
        { name: 'mobileImageSrc', path: ['mobileImage', 'fields', 'file', 'url'] },
        { name: 'alt', path: ['imageAlt'] },
      ],
    },
    'Image Full Width (with title)': {
      component: ImageSection,
      props: [
        'title',
        { name: 'imageSrc', path: ['image', 'fields', 'file', 'url'] },
        { name: 'mobileImageSrc', path: ['mobileImage', 'fields', 'file', 'url'] },
        { name: 'alt', path: ['imageAlt'] },
        { name: 'isFullWidth', getter: T },
      ],
    },
    'Credited Image': {
      component: ArticleFigureBlock,
      props: [{ name: 'image', getter: identity }],
    },
    Video: {
      component: ArticleFigureBlock,
      props: [{ name: 'image', getter: identity }, 'videoId', 'videoHost', 'backgroundPattern'],
    },
    'Video (Full Width)': {
      component: VideoFullWidthSection,
      props: ['image', { name: 'alt', path: ['imageAlt'] }, 'videoId', 'videoHost'],
    },
    'Image with background': {
      component: ImageWithBackgroundSection,
      props: [
        { name: 'name', path: ['title'] },
        'image',
        'imageAlt',
        'background',
        'backgroundAlt',
      ],
    },
  }),
  [SUBSCRIBE_SECTION]: {
    component: SubscribeSection,
    props: ['name', 'form', 'background', 'image'],
  },
  [EXTERNAL_EMBED]: basedOnType({
    'default': {
      component: IframeForm,
      props: ['scriptSrc', 'name', 'embededElement', 'type', 'boxWrap'],
    },
    iframe: {
      component: Iframe,
      props: ['iframeSrc', 'iframeMaxHeight'],
    },
    other: {
      component: ExternalEmbed,
      props: ['scriptSrc', 'name', 'embededElement'],
    },
  }),
  [NEWS_LIST]: basedOnType({
    'Campaign List': {
      component: ArticleListBackgroundSection,
      props: ['title', 'background', { name: 'articleList', path: ['items'] }],
    },
    'Campaign Spotlight List': {
      component: SignInList,
      props: [
        'title',
        'labelAboveTitle',
        { name: 'subtitle', path: ['description'] },
        { name: 'url', getter: getFieldIn(['url', 'url']) },
        { name: 'list', path: ['items'] },
        'background',
      ],
    },
    'Article List (2)': {
      component: CardListTwoColumns,
      props: [
        'title',
        { name: 'sectionText', path: ['description'] },
        { name: 'seeMoreButtonUrl', getter: getFieldIn(['url', 'url']) },
        'background',
        {
          name: 'cards',
          path: ['items'],
        },
      ],
    },
    'Article List (3)': component => {
      const itemsCount = (getField('items', component) || List()).size;
      const ONE_ROW_CONFIG = {
        component: CardListThreeColumns,
        props: [
          'title',
          { name: 'seeMoreButtonUrl', getter: getFieldIn(['url', 'url']) },
          'background',
          {
            name: 'cards',
            path: ['items'],
          },
        ],
      };
      const MULTIPLE_ROW_CONFIG = {
        component: ArticleCardsList,
        props: ['background', 'items', 'title'],
      };

      return itemsCount > 3 ? MULTIPLE_ROW_CONFIG : ONE_ROW_CONFIG;
    },
    'Category Carousel': {
      component: CategoryCarousel,
      props: [
        { name: 'label', path: ['labelAboveTitle'] },
        'title',
        { name: 'categories', path: ['items'] },
      ],
    },
    'Featured List': {
      component: FeaturedInSection,
      props: ['title', 'background', { name: 'categoriesWithCards', path: ['items'] }],
    },
    'News List (with Author)': {
      component: LegalActionSection,
      props: [
        'title',
        { name: 'url', getter: getFieldIn(['url', 'url']) },
        { name: 'author', path: ['author'] },
        { name: 'articleList', path: ['items'] },
        { name: 'background', path: ['background'] },
      ],
    },
  }),
  [LIST_SECTION]: basedOnType({
    'Image with Text List': {
      component: ImageWithTextList,
      props: ['title', 'narrow', { name: 'list', path: ['items'] }],
    },
    'Wide Cards List': {
      component: WideCardsList,
      props: ['name', 'title', 'description', { name: 'list', path: ['items'] }],
    },
    'Statistics Vertical List': {
      component: VerticalStatisticsList,
      props: [
        'title',
        { name: 'list', path: ['items'] },
        { name: 'backgroundTopColor', getter: getFieldIn(['backgroundTopColor', 'color']) },
        { name: 'backgroundBottomColor', getter: getFieldIn(['backgroundBottomColor', 'color']) },
        'background',
        'backgroundImage',
      ],
    },
    'Carousel List': {
      component: CarouselSection,
      props: [
        'title',
        'description',
        { name: 'list', path: ['items'] },
        { name: 'sectionBackground', path: ['background'] },
      ],
    },
    'Badge List': {
      component: BadgesSection,
      props: [
        'title',
        { name: 'badges', path: ['items'] },
        {
          name: 'decorated',
          getter: data => {
            const background = getField('background', data);
            return (
              background &&
              getFieldIn(['mainColor', 'color'], background) !==
                getFieldIn(['secondaryColor', 'color'], background)
            );
          },
        },
        { name: 'backgroundColor', getter: getFieldIn(['background', 'mainColor', 'color']) },
      ],
    },
    'Statistics List': {
      component: ThreeColumnStatisticsSection,
      props: [
        'title',
        'description',
        'backgroundImage',
        { name: 'statisticBlock1', getter: getField(['items', '0']) },
        { name: 'statisticBlock2', getter: getField(['items', '1']) },
        { name: 'statisticBlock3', getter: getField(['items', '2']) },
      ],
    },
    'Donate option List': {
      component: OtherWaysToDonate,
      props: [
        'title',
        { name: 'subtitle', path: ['description'] },
        { name: 'donateOptions', path: ['items'] },
        'textAlignment',
      ],
    },
    'Cta List': {
      component: CtaCardsSection,
      props: [
        'title',
        { name: 'ctaCards', path: ['items'] },
        { name: 'bottomText', path: ['description'] },
        'background',
      ],
    },
    'Event List': {
      component: EventsSection,
      props: [
        'title',
        { name: 'url', getter: getFieldIn(['url', 'url']) },
        { name: 'eventList', path: ['items'] },
      ],
    },
  }),
  [NEWS_GRID_SECTION]: {
    component: AllNewsSection,
    props: ['name', 'title', 'subtitle', { name: 'pageSize', path: ['initialItemsCount'] }],
  },
  [DECORATION_SECTION]: basedOnType({
    'Line Decoration': {
      component: SectionDecoration,
      props: [
        {
          name: 'hideOnMobile',
          getter: data => {
            const type = getField('displayOn', data);
            return type === 'Desktop only';
          },
        },
        {
          name: 'hideOnDesktop',
          getter: data => {
            const type = getField('displayOn', data);
            return type === 'Mobile only';
          },
        },
      ],
    },
    'Triangle Decoration': {
      component: SectionTriangleDecoration,
      props: [
        { name: 'mainColor', getter: getFieldIn(['background', 'mainColor']) },
        { name: 'secondaryColor', getter: getFieldIn(['background', 'secondaryColor']) },
      ],
    },
  }),
  [LINKS_SECTION]: {
    component: LinksSection,
    props: ['title', 'linkLists'],
  },
  [ARTICLE_STATS_SECTION]: {
    component: ArticleStatsBlock,
    props: ['text', 'stats'],
  },
  [ARTICLE_QUOTE]: {
    component: ArticleQuoteBlock,
    props: ['quote'],
  },
  [LINK]: basedOnType({
    Link: {
      component: PageLinkSection,
      props: [
        'title',
        'text',
        'label',
        { name: 'linkUrl', getter: getFieldIn(['url', 'url']) },
        { name: 'isYellow', path: ['useSecondaryColor'] },
      ],
    },
    'Button with Text': {
      component: ArticleCtaBlock,
      props: [
        'title',
        'text',
        'label',
        { name: 'buttonLinkUrl', getter: getFieldIn(['url', 'url']) },
        { name: 'buttonFileToDownload', getter: getField('fileToDownload') },
      ],
    },
  }),
  [FLAG_CTA]: {
    component: FlagCta,
    props: [
      'title',
      'image',
      { name: 'linkUrl', getter: getFieldIn(['url', 'url']) },
      'fileToDownload',
    ],
  },
  [JOB_LIST_SECTION]: {
    component: JobListSection,
    props: [
      'title',
      'description',
      'descriptionUnderList',
      'showLinkedin',
      'greenhouseListId',
      'peopleHrJobsFeedUrl',
      'includedDepartments',
      'excludedDepartments',
      'singleJob',
      'redirectUrl',
    ],
  },
  [WIDE_IMAGE_SECTION]: basedOnType({
    'Text Box': {
      component: WideImageSection,
      props: [
        'image',
        'title',
        'text',
        'buttonLabel',
        'buttonUrl',
        'isTextLeft',
        'videoId',
        'videoHost',
        {
          name: 'backgroundColor',
          getter: getFieldIn(['backgroundColor', 'color']),
        },
        {
          name: 'imageAlt',
          getter: getFieldIn(['image', 'title']),
        },
      ],
    },
    'Donate Box': {
      component: WideImageDonateSection,
      props: [
        {
          name: 'title',
          getter: getField('donateBlockTitle'),
        },
        {
          name: 'text',
          getter: getField('donateBlockDescription'),
        },
        {
          name: 'url',
          getter: getField('donateBlockUrl'),
        },
        {
          name: 'isRecurring',
          getter: getField('donateBlockIsRecurring'),
        },
        {
          name: 'defaultAmount',
          getter: getField('donateBlockDefaultAmount'),
        },
        'image',
        {
          name: 'imageAlt',
          getter: getFieldIn(['image', 'title']),
        },
        {
          name: 'backgroundColor',
          getter: getFieldIn(['backgroundColor', 'color']),
        },
        'isTextLeft',
      ],
    },
  }),
  [LIST_WITH_IMAGE_SECTION]: {
    component: ListWithImageSection,
    props: [
      'title',
      {
        name: 'image',
        path: ['image'],
      },
      'list',
    ],
  },
  [STATISTIC_ITEM]: basedOnType({
    'Progress Bar': {
      component: ProgressBar,
      props: ['header', 'description', 'statisticValue', 'statisticGoal'],
    },
  }),
  // HomePage Components
  [BUTTON_ARRAY]: {
    component: ButtonArray,
    props: [
      'name',
      {
        name: 'buttons',
        getter: object => {
          const MAX_BUTTON_ARRAY_LEN = 5;
          const buttonArray = [];

          for (let i = 1; i <= MAX_BUTTON_ARRAY_LEN; i++) {
            const button = getField(`button${i}_url`, object);
            const url = getField('url', button);
            const name = getField('name', button);
            let hasEmbellishment = getField(`button${i}_withEmbellishment`, object);

            if (url !== undefined && name !== undefined) {
              if (hasEmbellishment === undefined) {
                hasEmbellishment = false;
              }

              buttonArray.push({
                hasEmbellishment,
                url,
                name,
              });
            }
          }

          return buttonArray;
        },
      },
      {
        name: 'color',
        getter: item => camelCaseify(getField('color', item)),
      },
    ],
  },
  [COLOR_CHANGEABLE_DIVIDER]: {
    component: ColorChangeableDivider,
    props: [{ name: 'color', getter: item => camelCaseify(getField('color', item)) }],
  },
  [DYNAMIC_HERO_CAROUSEL]: {
    component: DynamicHeroCarousel,
    props: [
      {
        name: 'slides',
        getter: item => {
          const slides = getField('slides', item);
          return slides.map(slide => {
            return {
              id: getId(slide),
              heading: getField('heading', slide),
              text: getField('text', slide),
              link: {
                text: getField(['link', 'fields', 'name'], slide),
                url: getField(['link', 'fields', 'url'], slide),
              },
              colorTheme: camelCaseify(getField('colorTheme', slide)),
              heroImageDesktop: getField('heroImageDesktop', slide),
              heroImageMobile: getField('heroImageMobile', slide),
              heroVideoDesktop: getField('heroVideoDesktop', slide),
              heroVideoMobile: getField('heroVideoMobile', slide),
            };
          });
        },
      },
      'mobileSlide',
    ],
  },
  [FRAMED_CONTENT]: {
    component: FramedContent,
    props: [
      'name',
      {
        name: 'badges',
        getter: object => {
          const badges = getField('badgeContainer', object);
          const badgeArray = Array.from(badges);

          return badgeArray.map(badge => {
            const image = getField('image', badge);
            const imageTitle = getField('title', image);
            const imageDescription = getField('description', image);
            const imageFile = Array.from(getField('file', image))[0][1];

            const url = getField('url', badge);
            const urlName = getField('name', url);
            const urlAddress = getField('url', url);

            return {
              title: getField('title', badge),
              type: getField('type', badge),
              name: getField('name', badge),
              imageAlt: getField('imageAlt', badge),
              text: getField('text', badge),
              image: {
                imageTitle,
                imageDescription,
                imageFile,
              },
              url: {
                urlName,
                url: urlAddress,
              },
            };
          });
        },
      },
      {
        name: 'featuredBadge',
        getter: object => {
          const badge = getField('featuredBadge', object);

          const image = getField('image', badge);
          const imageTitle = getField('title', image);
          const imageDescription = getField('description', image);
          const imageFile = Array.from(getField('file', image))[0][1];

          const url = getField('url', badge);
          const urlName = getField('name', url);
          const urlAddress = getField('url', url);

          return {
            title: getField('title', badge),
            type: getField('type', badge),
            name: getField('name', badge),
            imageAlt: getField('imageAlt', badge),
            text: getField('text', badge),
            image: {
              imageTitle,
              imageDescription,
              imageFile,
            },
            url: {
              urlName,
              url: urlAddress,
            },
          };
        },
      },
      {
        name: 'linkTitle',
        getter: item => {
          const linkItem = getField('link', item);
          return getField('name', linkItem);
        },
      },
      {
        name: 'linkUrl',
        getter: item => {
          const linkItem = getField('link', item);
          return getField('url', linkItem);
        },
      },
      { name: 'cardTitle', path: 'title' },
      { name: 'cardContent', path: 'content' },
    ],
  },
  [ONE_UP_NEWS_MAJOR]: {
    component: OneUpNewsMajor,
    props: [
      'url',
      'headline',
      'date',
      'description',
      {
        name: 'imageSrc',
        path: ['image', 'fields', 'file', 'url'],
      },
      { name: 'imageAlt', path: ['image', 'fields', 'title'] },
      {
        name: 'links',
        getter: item => {
          const links = getField('links', item);
          const linksArray = links ? Array.from(links) : [];
          return linksArray.map(link => {
            return {
              label: getField('name', link),
              url: getField('url', link),
            };
          });
        },
      },
      'isVideo',
    ],
  },
  [THIRD_BLOCK]: {
    component: ThirdBlock,
    props: [
      'headline',
      'description',
      'buttonText',
      'buttonUrl',
      {
        name: 'imageSrc',
        path: ['image', 'fields', 'file', 'url'],
      },
      { name: 'imageAlt', path: ['image', 'fields', 'title'] },
    ],
  },
  [THREE_UP_IMPACT_STATS]: {
    component: ThreeUpImpactStats,
    props: [
      { name: 'colorTheme', getter: item => camelCaseify(getField('colorTheme', item)) },
      {
        name: 'statBlocks',
        getter: item => {
          return [
            {
              id: 'stat1',
              icon: getField('stat1Icon', item),
              stat: getField('stat1Value', item),
              description: getField('stat1Description', item),
            },
            {
              id: 'stat2',
              icon: getField('stat2Icon', item),
              stat: getField('stat2Value', item),
              description: getField('stat2Description', item),
            },
            {
              id: 'stat3',
              icon: getField('stat3Icon', item),
              stat: getField('stat3Value', item),
              description: getField('stat3Description', item),
            },
          ];
        },
      },
    ],
  },
  [THREE_UP_SCROLLING_BLOCK]: {
    component: ThreeUpScrollingBlock,
    props: [
      { name: 'mobileLayout', getter: item => camelCaseify(getField('mobileLayout', item)) },
      {
        name: 'mobileFirstStory',
        getter: item => {
          const mobileFirstStoryIndex = getField('mobileFirstStory', item);
          const storyBlockCount = getField('storyBlocks', item).toArray().length;
          // Randomize mobile first story if no valid story index is supplied
          if (!mobileFirstStoryIndex || mobileFirstStoryIndex > storyBlockCount) {
            return null;
          }
          const scrollingBlockName = getField('name', item).split(' ').join('-').toLowerCase();
          const mobileFirstStoryId = `${scrollingBlockName}-story-${mobileFirstStoryIndex}`;
          return mobileFirstStoryId;
        },
      },
      {
        name: 'storyBlocks',
        getter: item => {
          const storyBlocks = getField('storyBlocks', item);
          const scrollingBlockName = getField('name', item).split(' ').join('-').toLowerCase();
          return storyBlocks.toArray().map((storyBlock, i) => ({
            id: `${scrollingBlockName}-story-${i + 1}`,
            link: getField('link', storyBlock),
            image: getField('image', storyBlock),
            headline: getField('headline', storyBlock),
            text: getField('text', storyBlock),
            button: camelCaseify(getField('button', storyBlock)),
            tallyPetitionId: getField('tallyPetitionId', storyBlock),
            tallyFallbackText: getField('tallyFallbackText', storyBlock),
            ctaText: getField('ctaText', storyBlock),
            ctaColor: camelCaseify(getField('ctaColor', storyBlock)),
            embellishment: camelCaseify(getField('embellishment', storyBlock)),
            progressTagText: getField('progressTagText', storyBlock),
          }));
        },
      },
    ],
  },
  [ANCHORED_SCROLL_TEXT]: {
    component: AnchoredScrollText,
    props: ['name'],
  },
  [MISSION_BREAKOUT]: {
    component: MissionBreakout,
    props: [
      'bgMovieMobile',
      'bgMovieDesktop',
      'showAnchoredScrollText',
      {
        name: 'bgImageFallback',
        path: ['bgImageFallback', 'fields', 'file', 'url'],
      },
      'headlineComplete',
      'text',
      {
        name: 'link',
        getter: item => ({
          href: getField('url', getField('link', item)),
          text: getField('name', getField('link', item)),
        }),
      },
    ],
  },
  [FUNDRAISING_HERO]: {
    component: FundraisingHero,
    props: [
      'heroDesktop',
      'heroMobile',
      'hasCountdown',
      'countdownEndDate',
      'headline',
      'description',
      {
        name: 'givingValues',
        getter: item => {
          return {
            firstMonthly: getField('firstMonthlyValue', item),
            secondMonthly: getField('secondMonthlyValue', item),
            thirdMonthly: getField('thirdMonthlyValue', item),
            firstOneTime: getField('firstOneTimeValue', item),
            secondOneTime: getField('secondOneTimeValue', item),
            thirdOneTime: getField('thirdOneTimeValue', item),
          };
        },
      },
      'preselectedMonthly',
      'preselectedOneTime',
      'impactTextMonthly',
      'impactTextOneTime',
      {
        name: 'badges',
        getter: object => {
          const badges = getField('badges', object);
          if (!badges) {
            return null;
          }

          const badgeArray = Array.from(badges);

          return badgeArray.map(badge => {
            const image = getField('image', badge);
            const imageTitle = getField('title', image);
            const imageDescription = getField('description', image);
            const imageFile = Array.from(getField('file', image))[0][1];

            const url = getField('url', badge);
            const urlName = getField('name', url);
            const urlAddress = getField('url', url);

            return {
              title: getField('title', badge),
              type: getField('type', badge),
              name: getField('name', badge),
              imageAlt: getField('imageAlt', badge),
              text: getField('text', badge),
              image: {
                imageTitle,
                imageDescription,
                imageFile,
              },
              url: {
                urlName,
                url: urlAddress,
              },
            };
          });
        },
      },
      { name: 'colorTheme', getter: item => camelCaseify(getField('colorTheme', item)) },
      'buttonTextMonthly',
      'buttonTextOneTime',
      'defaultFrequency',
    ],
  },
  [GIVING_BREAKOUT]: {
    component: GivingBreakout,
    props: [
      'heroDesktop',
      'heroMobile',
      'description',
      {
        name: 'givingValues',
        getter: item => {
          return {
            firstMonthly: getField('firstMonthlyValue', item),
            secondMonthly: getField('secondMonthlyValue', item),
            thirdMonthly: getField('thirdMonthlyValue', item),
            firstOneTime: getField('firstOneTimeValue', item),
            secondOneTime: getField('secondOneTimeValue', item),
            thirdOneTime: getField('thirdOneTimeValue', item),
          };
        },
      },
      'preselectedMonthly',
      'preselectedOneTime',
    ],
  },
  [LEGACY_GIVING_BREAKOUT]: {
    component: LegacyGivingBreakout,
    props: ['heroDesktop', 'heroMobile', 'description'],
  },
  [NOTCHED_HEADER_SECTION]: {
    component: NotchedHeaderSection,
    props: [
      'headline',
      'subheadline',
      { name: 'notch', getter: item => camelCaseify(getField('notch', item)) },
      { name: 'background', getter: item => camelCaseify(getField('background', item)) },
      'buttonLabel',
      'buttonUrl',
      { name: 'buttonColor', getter: item => camelCaseify(getField('buttonColor', item)) },
      {
        name: 'content',
        // Using ContentManager to return child components
        getter: item => <ContentManager content={getField('content', item)} />,
      },
    ],
  },
};

export const getDisplayComponent = component => {
  const componentType = getComponentName(component);
  const componentConfig = COMPONENTS_MAP[componentType];

  if (componentConfig) {
    const contentTypeConfig =
      typeof componentConfig === 'function' ? componentConfig(component) : componentConfig;
    return typeof contentTypeConfig === 'function'
      ? contentTypeConfig(component)
      : contentTypeConfig;
  }

  return {};
};
